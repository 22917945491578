import React, { Component } from 'react';

const UserAnswersContext = React.createContext();

export class UserAnswersProvider extends Component {
    state = {
        answers: [],
        location: [],
        questionList: [],
        questionLength: 0,
        onTap: {},
        beersSelected: {},
        resultsHash: '',
    };

    componentDidMount() {
        if(document.location.pathname === '/') {
            fetch('https://api.piwolucja.pl/questions')
            .then(response => response.json())
            .then(response => {
                    this.setState({ questionList: response, questionLength: response.length })
                }
            );
        }
    }

    generateHash = () => {
        return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r && 0x3) || 0x8);
          return v.toString(16);
        });
    }

    sendAnswers = (answers, email, sendNewsletter) => {
        const generateHash = this.generateHash();

        const data = {
            answers: answers,
            email: email,
            newsletter: sendNewsletter,
            resultsHash: generateHash,
        };
        this.setState({resultsHash: generateHash})
        fetch('https://api.piwolucja.pl/results',{
            headers: {'Content-Type': 'application/json'},
            method: 'post',
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 200) {
                    response.json()
                    .then(() => {
                        window.location.href = `${window.location.origin}/index.html?id=${generateHash}`;
                    })
                } else if (response.status === 204) {
                    console.error('No data')
                } else {
                    console.error('No valid response')
                }     
            })
    };

    nativeSmoothScrollTo = elem => {
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: elem.getBoundingClientRect().top + window.pageYOffset
        });
    };
    
    // polyfilled smooth scrolling for IE, Edge & Safari
    smoothScrollTo = (to, duration) => {
        const element = document.scrollingElement || document.documentElement,
            start = element.scrollTop,
            change = to - start,
            startDate = +new Date();
    
        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        const easeInOutQuad = (t, b, c, d) => {
            t /= d/2;
            if (t < 1) return c/2*t*t + b;
            t--;
            return -c/2 * (t*(t-2) - 1) + b;
        };
    
        const animateScroll = _ => {
            const currentDate = +new Date();
            const currentTime = currentDate - startDate;
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
            if(currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
            else {
                element.scrollTop = to;
            }
        };
        animateScroll();
    };
    
    // detect support for the behavior property in ScrollOptions
    supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    
    scrollToElem = elemSelector => {
        if (!elemSelector) {
            return;
        }

        const isFirefox = typeof InstallTrigger !== 'undefined';
        const elem = document.getElementById(elemSelector);

        if (elem) {
            if (isFirefox) {
                this.smoothScrollTo(elem.offsetTop, 600);
            } else if (this.supportsNativeSmoothScroll) {
                this.nativeSmoothScrollTo(elem);
            } else {
                this.smoothScrollTo(elem.offsetTop, 600);
            }
        }
    };

    smoothScroll = (id) => {
        const content = document.getElementById(id + 1);
        const toggleText = (id, state) => {
            if (state === 'show') {
                content.style.display = 'flex';
                content.style.opacity = '1';
                content.style.transform = 'translateY(0)';
                this.scrollToElem(id+1)
            }
        };

        toggleText(id, 'show');
        
    };

    handleClickAnswerChosen = (elem, question, parentIndex, unmountMe) => {
        const { answers, questionLength } = this.state;

        if (answers.length !== 0) {
            let newAnswers = this.state.answers;
            newAnswers[parentIndex] = elem;
            this.setState({ answers: newAnswers });
        } else {
            const newAnswers = {[parentIndex]: elem};
            this.setState({answers: newAnswers});
        }

        if (Object.keys(answers).length >= questionLength) {
            unmountMe();
        } else {
            this.smoothScroll(parentIndex);
        }
    };

    render() {
        const { children } = this.props;

        return (
            <UserAnswersContext.Provider
                value={{
                    handleClickAnswerChosen: this.handleClickAnswerChosen,
                    getQuestions: this.getQuestions,
                    answers: this.state.answers,
                    sendAnswers: this.sendAnswers,
                    questions: this.state.questionList,
                    smoothScroll: this.smoothScroll,
                    beersSelected: this.state.beersSelected,
                    onTap: this.state.onTap,
                    questionLength: this.state.questionLength,
                    resultsHash: this.state.resultsHash,
                }}
            >
                { children }
            </UserAnswersContext.Provider>
        );
    }
}

export const UserAnswersConsumer = UserAnswersContext.Consumer;
