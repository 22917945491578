import React, { Component } from 'react'
import styled from 'styled-components'

export class Header extends Component {
    render() {
        const { unmountMe, userName } = this.props;
        return (
            <HeaderHeader className="section" key={1}>
                <AppBox>
                    <AppText>
                        <AppTitle>Degustator</AppTitle>
                        <PiwolucjaTag href="https://piwolucja.pl/" target='_blank' rel='noreferrer noopener'>by Piwolucja</PiwolucjaTag>
                    </AppText>
                </AppBox>
                <WelcomeText>
                    Degustator to wirtualny kolega, który na podstawie Twoich preferencji
                    poleci najlepsze piwa dla Ciebie. Wystarczy, że odpowiesz na kilka pytań...
                </WelcomeText>
                <HeaderButton onClick={unmountMe}>
                    {userName ? `Zaczynajmy, ${userName}` : 'Zaczynajmy'}
                </HeaderButton>
            </HeaderHeader>
        )
    }
}

const AppTitle = styled.h1`
    width: 290px;
    font-family: BiGPoiNts;
    font-size: 140px;
    text-align: justify;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fcfcf5;
    cursor: context-menu;

    @media (min-width: 601px) {
        height: 168px;
    } 

    @media (max-width: 600px) {
        font-size: 100px;
        text-align: center;
    } 
`;

const PiwolucjaTag = styled.a`
    width: 131px;
    height: 22px;
    font-size: 18px;
    text-align: justify;
    color: #dc8400;
    text-transform: uppercase;
    position: relative;
    bottom: 120px;
    text-decoration: none;

    @media (min-width: 600px) {
        left: 20px;
    }

    @media (max-width: 600px) {
        right: 30px;
        bottom: 78px;
        width: 84px;
        height: 13px;
        font-size: 11px;
        font-weight: bold;
    }
`;

const AppBox = styled.div`
    height: 50vh;
    width: 100%;
    background: #fbc601;
    justify-content: center;
    display: flex;

    @media (max-width: 320px) {
        height: 35vh;
    }
`;

const AppText = styled.div`
    width: 290px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: center;
`;

const HeaderHeader = styled.header`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: #282c34;

    @media (max-width: 360px) {
        min-height: 80vh;
    }
`;

const HeaderButton = styled.button`
    color: #fcfcf5;
    cursor: pointer;
    background-color: #dc8400;
    width: 268px;
    height: 48px;
    border-radius: 2px;
    text-transform: uppercase;
    margin-top: 56px;
    
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: center;
    border: none;

    &:hover {
        background-color: #fbc602;
    }

    &:focus {
        outline: none;
    }
`;

const WelcomeText = styled.p`
    width: 406px;
    height: 87px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: center;
    color: #5c4d38;
    margin-top: 12.35vh;

    @media (max-width: 600px) {
        width: 275px;
        margin-top: 6.17vh;
    } 
`;
