import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { UserAnswersConsumer, UserAnswersProvider } from './UserAnswers.context';

import { Header } from './Content/Header'
import { Question } from './Content/Question'
import { UserForm } from './Content/UserForm';
import { Response } from './Content/Response';

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            renderHeader: true,
            renderQuestions: false,
            renderForm: false,
            renderBeers: false,
            userName: '',
        };
        this.handleHeaderUnmount = this.handleHeaderUnmount.bind(this);
        this.handleQuestionsUnmount = this.handleQuestionsUnmount.bind(this);
        this.handleFormUnmount = this.handleFormUnmount.bind(this);
    }

    componentDidMount() {
        const nameEQ = "userName=";
        const cookies = document.cookie.split(';');
        let userName;

        for(var i=0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') cookie = cookie.substring(1,cookie.length);
            if (cookie.indexOf(nameEQ) === 0) {
                userName = cookie.substring(nameEQ.length,cookie.length);
                this.setState({userName: userName});
            }
        }

        document.location.pathname !== '/' && this.redirect();

        return null;
    }

    handleHeaderUnmount(){
        this.setState({
            renderHeader: false,
            renderQuestions: true,
        });
    }

    handleQuestionsUnmount() {
        this.setState({
            renderQuestions: false,
            renderForm: true,
        });
    }

    handleFormUnmount() {
        this.setState({
            renderForm: false,
            renderBeers: true,
        });
    }

    redirect() {
        const params = new URLSearchParams(window.location.search);
        const model = params.get('id');
        const resultsHash = model;

        fetch(`https://api.piwolucja.pl/results/${resultsHash}`)
        .then(response => response.json())
        .then(response => {
            const beersSelected = response;

            if (beersSelected) {
                ReactDOM.render((
                    <Response
                        beersSelected={beersSelected}
                        resultsHash={resultsHash}
                    />
                ), document.getElementById('element'));
            }
        });
    }

    stay() {
        const { renderHeader, renderQuestions, renderForm, userName } = this.state;
        return (
            <React.Fragment>{renderHeader &&
                <React.Fragment>
                    <Header unmountMe={this.handleHeaderUnmount} userName={userName}/>
                </React.Fragment>}
            {renderQuestions &&
                <React.Fragment>
                    <UserAnswersConsumer>
                        {({ questionLength }) => (
                            <Question questionLength={questionLength} unmountMe={this.handleQuestionsUnmount}/>
                        )}
                    </UserAnswersConsumer>
                </React.Fragment>}
            {renderForm &&
                <React.Fragment>
                    <UserAnswersConsumer>
                    {({ answers, sendAnswers, questionLength }) => (
                        <UserForm
                            userName={userName}
                            answers={answers}
                            sendAnswers={sendAnswers}
                            questionLength={questionLength}
                        />)}
                    </UserAnswersConsumer>
                </React.Fragment>}
            </React.Fragment>
        );
    }

    render() {
         return (
              <UserAnswersProvider>
                  <div id='element'></div>
                  {document.location.pathname === '/' && this.stay()}
              </UserAnswersProvider>
        )
    }
}

export default App
