import React, { Component } from 'react'
import styled from 'styled-components'
import {UserAnswersConsumer} from "../../UserAnswers.context";

export class QuestionBox extends Component {

  renderAnswers = (elem, index, question, handleClickAnswerChosen, parentIndex, unmountMe) => (
    <AnswerBox key={index}>
        <ButtonTag name={question} value={elem} onClick={() => handleClickAnswerChosen(elem, question, parentIndex, unmountMe)}>
            <Label>{elem}</Label>
        </ButtonTag>
    </AnswerBox>
  );

  render(){
    const { answer, question, parentIndex, unmountMe } = this.props;
    return (
        <UserAnswersConsumer>
          {({ questions, handleClickAnswerChosen }) => (
              <div>
                {questions ?
                    <QuestionBoxDiv>
                      {answer.map((elem, index) => this.renderAnswers(
                          elem, index, question, handleClickAnswerChosen, parentIndex, unmountMe
                      ))}
                    </QuestionBoxDiv>
                    : null}
              </div>
          )}
        </UserAnswersConsumer>
    )
  }
}

const QuestionBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //font-size: calc(11px + 2vmin);
  color: #282c34;
  width: fit-content;
  margin: auto;
  align-items: flex-end;
  padding-top: 56px;

  @media (max-width: 320px) {
    padding-top: 30px;
  }
`;

const ButtonTag = styled.button`
  width: 240px;
  height: 48px;
  border-radius: 2px;
  background-color: #dc8400;

  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px;
  color: #fcfcf5;
  
  &:hover {
    background-color: #fbc602;
  }

  @media (max-width: 600px) {
    width: 220px;
    height: 48px;
  }  

  &:focus {
    outline: none;
  }
`;

const AnswerBox = styled.div`
  cursor: pointer;
  margin: 10px;
`;

const Label = styled.label`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
`;
