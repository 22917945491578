import React from 'react'
import styled from 'styled-components'

export const EmptyBeer = () => {
    return (
        <BeerDetails>
            <BeerText>
                <BeerDescription>
                    <BeerNameText>
                        BRAK POLECANYCH W STYM STYLU
                    </BeerNameText>
                </BeerDescription>
                <BeerRating>
                    <BeerRatingText>
                        0.0
                    </BeerRatingText>
                    <RatingStarBox>
                        <PolishCraft>PolskiKraft.pl</PolishCraft>
                    </RatingStarBox>
                </BeerRating>
            </BeerText>
            <BeerCont><BeerImage>D</BeerImage></BeerCont>
        </BeerDetails>
    )
}

const BeerDetails = styled.div`
    display: flex;
    
    height: 156px;
    object-fit: contain;
    background: linear-gradient(180deg,#f4d7ac 113px, #fcfcf5 0px);

    @media (min-width: 674px) {
        width: 400px;
        padding-bottom: 20px;
        margin: 0 20px;
    }

    @media (max-width: 897px) {
        max-width: 500px;
        width: 100%;
        padding-bottom: 20px;
        margin: 0 20px;
    }

    @media (max-width: 897px) {
        display: none;

        &:first-of-type {
            display: flex;
            width: inherit;
            // margin-bottom: 64px;
        }
    }
`;

const BeerText = styled.div`
    width: 100%;
`;

const BeerDescription = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 113px;
    align-content: flex-end;
    // width: 148px;
`;

const BeerNameText = styled.span`
    width: 100%;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fcfcf5;
    text-transform: uppercase;
    padding: 0 10px 13px 16px;
`;

const BeerRating = styled.div`
    height: 43px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding-left: 16px;
    width: 132px;
`;

const BeerRatingText = styled.div`
    font-family: Lato;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #dc8400;
`;

const RatingStarBox = styled.div`
    padding: 0 14px;
`;

const PolishCraft = styled.a`
    font-family: Lato;
    font-size: 11px;
    color: #dc8400;
    text-decoration: none;
`;

const BeerImage = styled.div`
    width: 148px;
    height: 148px;
    display: flex;
    align-self: flex-end;
    border-radius: 2px;
    margin-right: 24px;
    font-family: BiGPoiNts;
    font-size: 150px;

    color: #fcfcf5;
    border-radius: 2px;
    background-color: #d1b288;
    justify-content: center;
    align-items: center;

    @media (max-width: 359px) {
        width: 132px;
        height: 132px;
        margin-right: 0px;
    }
`;

const BeerCont = styled.div`
    display: flex;
`;
