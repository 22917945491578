import React, { Component } from 'react'
import styled from 'styled-components'
import { EmptyBeer } from './EmptyBeer';
import star from '../../beerIcons/wyroznienie.png';

export class BeersRecommended extends Component {

    constructor(props) {
        super(props);
        this.state = {
            onTap: null,
            localizationSupported: true,
            localizationAllowed: true,
            userLocationAgreement: false,
            locationModalWasShown: false,
            userLocation: {
                latitude: null,
                longitude: null
            }
        }
    }

    componentDidMount() {
        const locationModal = "locationModalWasShown=";
        const userAgreement = "userAgreement=";
        const cookies = document.cookie.split(';');
        let wasShown;
        let locationAgree;

        for(var i=0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') cookie = cookie.substring(1,cookie.length);

            if (cookie.indexOf(locationModal) === 0) {
                wasShown = cookie.substring(locationModal.length, cookie.length) === 'true' ? true : false;
                this.setState({locationModalWasShown: wasShown});
            }

            if (cookie.indexOf(userAgreement) === 0) {
                locationAgree = cookie.substring(userAgreement.length, cookie.length) === 'true' ? true : false;
                this.setState({userLocationAgreement: locationAgree});
                if(locationAgree) {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(this.userPositionHandler.bind(this), this.disableLocationHandler.bind(this));
                    } else {
                        this.setState({localizationSupported: false});
                        console.error("Geolocation is not supported by this browser.");
                    }
                }
            }
        }
    }

    userPositionHandler = (position) => {
        const { latitude, longitude } = position.coords;
        this.setState({ userLocation: { latitude, longitude } });
        this.getOnTapBeers();
    }

    getOnTapBeers = () => {
        const { resultsHash } = this.props;
        const { userLocation } = this.state;

        if (userLocation.latitude && userLocation.longitude) {
            const data = {
                userLocation,
                "resultsHash": resultsHash,
            };

            fetch('https://api.piwolucja.pl/ontap', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'this-can-be-anything',
                },
                method: 'post',
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (response.status === 200) {
                        response.json()
                        .then(response => {
                            this.setState({onTap: response});
                        })
                    } else if (response.status === 204) {
                        console.error('No onTap beers')
                    } else {
                        console.error('No valid onTap response')
                    }
                })
        }
    }

    disableLocationHandler = (error) => {
        this.setState({localizationAllowed: false});
        console.warn(`Cannot localize user because: ${error.message}`)
    }

    renderEmptyBeers(beerCollectionLength) {
        const emptyBeer = [];

        for(let i=3; i >= beerCollectionLength; i--){
            if (i < 3) {
                emptyBeer.push(<EmptyBeer key={`EmptyBeer-${i}`}/>);
            }
        };

        return emptyBeer;
    }

    renderStarsRating(rating) {
        const stars = [];
        const cutedFirstNumber = rating.toString().slice(2);
        const afterDots = Number(cutedFirstNumber);
        const floorRating = Math.floor(rating);
        const roundRating = Math.round(rating);

        if (afterDots < 4) {
            for(let i=0; i < floorRating; i++) {
                stars.push(<i key={`StarFull-${i}`} style={{fontSize: "10px", color: "#dc8400", paddingRight: '5px'}} className="fa fa-star"></i>);
            };
        }
        
        if (afterDots >= 4 && afterDots <= 6) {
            for(let i=0; i < floorRating; i++) {
                stars.push(<i key={`StarFull-${i}`} style={{fontSize: "10px", color: "#dc8400", paddingRight: '5px'}} className="fa fa-star"></i>);
            };
            stars.push(
                <div style={{display: "inline-block"}} key='starrating'>
                    <i key="StarHalf" style={{fontSize: "10px", color: "#dc8400"}} className="fa fa-star-half"></i>
                    <i key="StarHalfEmpty" style={{
                        fontSize: "10px",
                        color: "#5c4d38",
                        paddingLeft: '5px',
                        transform: "matrix(-1, 0, 0, 1, 0, 0)",
                        position: "relative",
                        right: "1px",
                    }} className="fa fa-star-half"></i>
                </div>
            );
        }

        if (afterDots > 6) {
            for(let i=0; i < roundRating; i++) {
                stars.push(<i key={`StarFull-${i}`} style={{fontSize: "10px", color: "#dc8400", paddingRight: '5px'}} className="fa fa-star"></i>);
            };
        }

        const emptyStars = 5 - stars.length;
        for(let i=0; i < emptyStars; i++) {
            stars.push(<i key={`StarEmpty-${i}`} style={{fontSize: "11px", color: "#5c4d38", paddingRight: '4px'}} className="fa fa-star"></i>);
        };

        return stars;
    }

    agreementClick = () => {
        const expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 1);

        this.setState({userLocationAgreement: true});
        this.setState({locationModalWasShown: true});
        document.cookie=`locationModalWasShown=true; path=/; expires=${expiryDate.toUTCString()}; SameSite=Strict`;
        document.cookie=`userAgreement=true; path=/; expires=${expiryDate.toUTCString()}; SameSite=Strict`;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.userPositionHandler.bind(this), this.disableLocationHandler.bind(this));
        } else {
            this.setState({localizationSupported: false});
            console.error("Geolocation is not supported by this browser.");
        }
    }

    modalClick = () => {
        const expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 1);

        document.cookie=`locationModalWasShown=true; path=/; expires=${expiryDate.toUTCString()}; SameSite=Strict`;
        document.cookie=`userAgreement=false; path=/; expires=${expiryDate.toUTCString()}; SameSite=Strict`;
        this.setState({userLocationAgreement: false});
        this.setState({locationModalWasShown: true});
    }

    renderOnTap(beerDataTitle) {
        const {onTap} = this.state;
        let beer;

        onTap && onTap.data && onTap.data.find((beerName) => {
            for(var key in beerName){
                return beer = key === beerDataTitle ? beerName : null;
            }
            return beer;
        });

        const beerRendered = beer
            ?  (<BeerAvailability>
                    <AvailabilityIn>Dostępne w:</AvailabilityIn>
                    <AvailabilityPalce>{
                        beer[beerDataTitle].map((beerPlace, index) => {
                            if (index > 0) {
                                return (
                                    <BeerPlace href={beerPlace.url} target='_blank' rel='noreferrer noopener'>
                                        ,&nbsp;{beerPlace.name}
                                    </BeerPlace>
                                )
                            } else {
                                return (
                                    <BeerPlace href={beerPlace.url} target='_blank' rel='noreferrer noopener'>
                                        {beerPlace.name}
                                    </BeerPlace>
                                )
                            }
                        })
                    }</AvailabilityPalce>
                </BeerAvailability>) 
            :   <BeerNotAvailable>
                    <AvailabilityPalce>Brak piwa w pubach w Twojej okolicy.</AvailabilityPalce>
                </BeerNotAvailable>;
        return beerRendered;
    }

    render() {
        const { selectedBeers } = this.props;
        const { locationModalWasShown, userLocationAgreement } = this.state;

        return (
            <SelectedBeers>
                {!locationModalWasShown &&
                    <LocationModal>
                        <LocationModal2>
                            <LocationModal3>
                                <LocationModal4>
                                    <Localization>Lokalizacja</Localization>
                                    <LocationText>
                                        Dzięki lokalizacji jesteśmy w stanie
                                        pokazać Tobie dostępność kraftów w
                                        multitapach w Twojej okolicy! Lokalizacja jest używana
                                        jedynie do wyznaczenia rejonu
                                        i nie jest przechowywana na serwerach.
                                    </LocationText>
                                    <LocationButton type="button" onClick={this.agreementClick}>Pozwól</LocationButton>
                                    <LocationButton type="button" onClick={this.modalClick}>Nie pozwalaj</LocationButton>
                                </LocationModal4>
                            </LocationModal3>
                        </LocationModal2>
                    </LocationModal>}
                <SelectedBeerTitle>Piwa dla Ciebie</SelectedBeerTitle>
                {selectedBeers.map((beer, index) => {
                    const isBeerDataCollection = beer.beerDataCollection;
                    const beerCollectionLength = isBeerDataCollection && isBeerDataCollection.length;
                    const beerHighlighted = beer.highlighted;

                    return (
                        <BeerBox key={`BeerBox-${index}`}>
                            <BeerStyleBox>
                                {beerHighlighted ?
                                    <BeerStyleTextMain>
                                        {beer.name}
                                        <Tooltip>
                                            <RecommendedStar src={star} alt='recommendedStar'/>
                                            <TooltipText>Jestem pewny, że to styl dla Ciebie!</TooltipText>
                                        </Tooltip>
                                    </BeerStyleTextMain> :
                                    <BeerStyleText>
                                        {beer.name}
                                    </BeerStyleText>}
                                {beer.otherName &&
                                    <BeerStyleText>
                                        {`(${beer.otherName})`}
                                    </BeerStyleText>}
                            </BeerStyleBox>
                            {beer.polishName &&
                                <BeerPolishName>
                                    polska nazwa: {beer.polishName}
                                </BeerPolishName>}
                            {beer.description.text &&
                                <Description>
                                    {beer.description.text}&nbsp;
                                    {beer.description.url &&
                                        <DescriptionUrl href={beer.description.url} target='_blank' rel='noreferrer noopener'>
                                            {beer.description.urlText}
                                        </DescriptionUrl>
                                    }
                                </Description>}
                            {(isBeerDataCollection || isBeerDataCollection === null) && <BeerExamples>
                                {isBeerDataCollection && isBeerDataCollection.map((beerData, index) => {
                                    const beerRating = (Math.round( beerData.rating * 10 ) / 10).toFixed(1);
                                    return (
                                        <BeerFrame key={`BeerDetails-${index}`}>
                                            <BeerDetails>
                                                <BeerText>
                                                    <BeerDescription>
                                                        <BeerNameText>
                                                            {beerData.title}
                                                            {/* {beerData.title.length >= 23 && <TooltipText>{beerData.title}</TooltipText>} */}
                                                        </BeerNameText>
                                                        <BeerBreweryText>
                                                            {beerData.subtitle}
                                                        </BeerBreweryText>
                                                    </BeerDescription>
                                                    <BeerRating>
                                                        <BeerRatingText>
                                                            {beerRating}
                                                        </BeerRatingText>
                                                        <RatingStarBox>
                                                            {this.renderStarsRating(beerRating)}
                                                            <PolishCraft href={beerData.webUrl} >PolskiKraft.pl</PolishCraft>
                                                        </RatingStarBox>
                                                    </BeerRating>
                                                </BeerText>
                                                <BeerImageLink href={beerData.webUrl} target='_blank' rel='noreferrer noopener'>
                                                    <BeerImage src={beerData.photoThumbnailUrl} alt='beerImage'/>
                                                </BeerImageLink>
                                            </BeerDetails>
                                            {userLocationAgreement && this.renderOnTap(beerData.title)}
                                        </BeerFrame>
                                    )
                                })}
                                {beerCollectionLength < 3 && this.renderEmptyBeers(beerCollectionLength)}
                            </BeerExamples>}
                            {beer.moreUrlQuery && beerCollectionLength >= 3 &&
                                <ButtonContainer>
                                    <Restart href={`https://www.polskikraft.pl/szukaj/${beer.moreUrlQuery}`} target='_blank' rel='noreferrer noopener'>
                                        <MoreBeersButton>
                                            WIĘCEJ PIW
                                        </MoreBeersButton>
                                    </Restart>
                                </ButtonContainer>
                            }
                        </BeerBox>
                    )
                })}
            </SelectedBeers>
        )
    }
}

const SelectedBeers = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 673px) {
        justify-content: center;
    }
`;

const LocationModal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color:rgba(0, 0, 0, 0.5);
    align-content: center;
    justify-content: center;
    display: flex;
`;

const LocationModal2 = styled.div`
    margin-top: 200px;
    max-width: 500px;

    @media (max-width: 673px) {
        margin-top: 48px;
    }

    @media (max-width: 320px) {
        margin-top: 0;
    }
`;

const Localization = styled.h3`
    width: 100%;
    font-family: Lato;
    font-size: 23px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #5c4d38;
    text-transform: uppercase;
    text-align: center;
`;

const LocationModal3 = styled.div`
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    position: relative;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: 0;
`;

const LocationModal4 = styled.div`
    position: relative;
    padding: 20px;
    padding-bottom: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const LocationText = styled.div`
    text-align: center;
    color: #5c4d38;
    max-width: 927px;
    font-family: Lato;
    font-size: 16px;
    padding: 10px 10px 20px 10px;
`;

const LocationButton = styled.button`
    margin: 16px 5px 5px 5px;
    color: #fcfcf5;
    cursor: pointer;
    background-color: #fbc602;
    width: 268px;
    height: 48px;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    -webkit-letter-spacing: 0.4px;
    -moz-letter-spacing: 0.4px;
    -ms-letter-spacing: 0.4px;
    letter-spacing: 0.4px;
    text-align: center;
    border: none;

    &:hover {
        background-color: #dc8400;
    }
`;

const SelectedBeerTitle = styled.h2`
    width: 100%;
    font-family: Lato;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #dc8400;
    text-transform: uppercase;
    margin: 0;
    padding: 74px 10px 0px 10px;

    @media (max-width: 600px) {
        font-size: 24px;
        padding: 62px 10px 0px 10px;
    } 
`;

const BeerBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 673px) {
        padding-left: 24px;
    }

    @media (min-width: 674px) {
        justify-content: center;
    }
`;

const BeerText = styled.div`
    width: inherit;

    @media (max-width: 673px) {
        width: 100%;
    }
`;


const BeerPlace = styled.a`
    text-decoration: none;
    color: #5c4d38;
`;

const BeerStyleTextMain = styled.div`
    width: 100%;
    font-family: Lato;
    font-size: 23px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c4d38;
    text-transform: uppercase;

    @media (min-width: 674px) {
        text-align: center;
        padding-left: 45px;
    }
`;

const BeerStyleText = styled.div`
    width: 100%;
    font-family: Lato;
    font-size: 23px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c4d38;
    text-transform: uppercase;

    @media (min-width: 674px) {
        text-align: center;
    }
`;

const BeerPolishName = styled.div`
    width: 100%;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #dc8400;
    padding-top: 2px;

    @media (min-width: 674px) {
        text-align: center;
    }
`;

const Description = styled.div`
    color: #5c4d38;
    max-width: 927px;
    font-family: Lato;
    font-size: 16px;
    text-align: justify;
    padding-top: 26px;

    @media (min-width: 674px) {
        padding: 26px 16px 0 16px;
    }

    @media (max-width: 673px) {
        padding: 26px 16px 0 0;
    }
`;

const BeerDetails = styled.div`
    display: flex;
    object-fit: contain;
    background: linear-gradient(180deg,#dc8400 113px, #fcfcf5 0px);

    @media (min-width: 674px) {
        width: 100%;
        padding-bottom: 20px;
    }

    @media (max-width: 673px) {
        width: inherit;
    }
`;

const BeerExamples = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 80px 0 56px 0;

    @media (max-width: 980px) {
        padding: 48px 0 0 17px;
    }

    @media (max-width: 320px) {
        padding: 48px 0 0 0;
    }

    ${BeerDetails}:nth-child(2) {
        background: linear-gradient(180deg,#fbc602 113px, #fcfcf5 0px);

        @media (max-width: 600px) {
            margin-bottom: 64px;
            width: inherit;
        }
    }
`;

const BeerRatingText = styled.div`
    font-family: Lato;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c4d38;
`;

const BeerRating = styled.div`
    height: 43px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding-left: 16px;
`;

const RatingStarBox = styled.div`
    padding: 0 14px;
    max-width: 100px;
`;

const BeerDescription = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 113px;
    align-content: flex-end;

    // @media (min-width: 673px) {
    //     width: 148px;
    // }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: 200px;
  background-color: #fcfcf5;
  color: #282c34b3;
  box-shadow: 1px 1px #282c3436;
  text-align: center;
  padding: 5px 0;
  font-size: 18px;
  padding: 17px;

  /* Position the tooltip */
  position: absolute;
  z-index: 3;

  @media (max-width: 768px) {
    display: none;
  }
`;

const BeerNameText = styled.span`
    width: 100%;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    color: #fcfcf5;
    text-transform: uppercase;
    padding: 0 10px 2px 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    &:hover ${TooltipText} {
        visibility: visible;
    }
`;

const BeerBreweryText = styled.span`
    width: 100%;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fcfcf5;
    padding: 0 10px 13px 16px;

    text-overflow: ellipsis;
    overflow: hidden;
    // white-space: nowrap;
}
`;

const PolishCraft = styled.a`
    font-family: Lato;
    font-size: 11px;
    color: #dc8400;
    text-decoration: none;
`;

const BeerImageLink = styled.a`
    text-decoration: none;
    width: 148px;
    height: 148px;
    display: flex;
    align-self: flex-start;
    margin-top: 8px;
    border-radius: 2px;
    margin-right: 24px;

    @media (max-width: 359px) {
        width: 132px;
        height: 132px;
        margin-right: 0px;
    }
`;

const BeerImage = styled.img`
    width: 148px;
    height: 148px;
    transition: transform .2s;
    box-shadow: 1px 1px rgba(0,0,0,0.2);
    z-index: 1;

    @media (min-width: 600px) {
        &:hover {
            transform: scale(1.75);
        }
    }

    @media (max-width: 359px) {
        width: 132px;
        height: 132px;
        margin-right: 0px;
    }
`;

const BeerStyleBox = styled.div`
    align-items: center;
    border: none;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px 0 2px 0;
    width: 100%;
`;

const BeerFrame = styled.div`
    display: flex;
    flex-wrap: wrap;
    
    object-fit: contain;
    background: linear-gradient(180deg, #dc8400 113px, #fcfcf5 0px);

    @media (min-width: 674px) {
        width: 400px;
        padding-bottom: 20px;
        margin: 0 20px;
    }

    @media (max-width: 897px) {
        max-width: 500px;
        width: 100%;
        padding-bottom: 20px;
    }

    @media (max-width: 673px) {
        margin-bottom: 30px;
        width: inherit;
    }
`;

const BeerAvailability = styled.div`
    width: 320px;
    padding-bottom: 20px;
    padding-top: 21px;

    @media (max-width: 600px) {
        width: inherit;
        padding-top: 0px;
    }
`;

const AvailabilityPalce = styled.div`
    font-family: Lato;
    font-size: 14px;
    color: #5c4d38;
    padding-top: 16px;
`;

const BeerNotAvailable = styled.div`
    width: 320px;
    position: relative;
    top: -16px;

    @media (max-width: 600px) {
        width: inherit;
    }

    @media (max-width: 674px) {
        top: 0px;
    }

    &:${AvailabilityPalce} {
        padding-top: 0px;
    }
`;


const AvailabilityIn = styled.div`
    text-transform: uppercase;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    color: #5c4d38;
`;

const MoreBeersButton = styled.button`
    color: #fcfcf5;
    cursor: pointer;
    background-color: #fbc602;
    width: 268px;
    height: 48px;
    border-radius: 2px;
    text-transform: uppercase;
    
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: center;
    border: none;

    &:hover {
        background-color: #dc8400;
    }

    &:focus {
        outline: none;
    }
`;

const Restart = styled.a`
    text-decoration: none;
`;

const DescriptionUrl = styled.a`
    color: #5c4d38;
`;

const RecommendedStar = styled.img`
    height: 30px;
    top: 5px;
    position: relative;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
        padding-right: 24px;
    }

    @media (min-width: 600px) {
        margin-bottom: 64px;
    }
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 15px;

  &:hover ${TooltipText} {
    visibility: visible;
  }
`;
