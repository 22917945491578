import React, { Component } from 'react'
import styled from 'styled-components'
import { BeersRecommended } from "./components/BeersRecommended";
import { BeersNotRecommended } from "./components/BeersNotRecommended";
import facebookLogo from '../beerIcons/facebook.svg';
import instagramLogo from '../beerIcons/instagram.svg';
import untappdLogo from '../beerIcons/untappd.svg';
import barrel from '../beerIcons/barrel.png';

export class Response extends Component {
    render() {
        const { beersSelected, resultsHash } = this.props;

        const recommendedBeers = beersSelected ? beersSelected.buyThis : {};
        const notRecommendedBeers = beersSelected ? beersSelected.avoidThis : {};

        const currentYear = new Date().getFullYear()

        return (
            <ResponseBeers>
                <TopBar href='http://degustator.piwolucja.pl/'>
                    <TopLogo>Degustator</TopLogo>
                </TopBar>
                <BeersRecommended selectedBeers={recommendedBeers} resultsHash={resultsHash}/>
                {beersSelected.barrelAged &&
                    <Barrel>
                        <RecommendedBarrel src={barrel} alt='barrel'/>
                        <BarrelAgedText>Ponieważ lubisz alkohole szlachetne,
                            powinny zainteresować Cię piwa leżakowane w beczkach po trunkach takich jak whisky czy bourbon.
                            Szukaj w sklepie piw z dopiskiem "barrel-aged" lub "BA" na etykiecie.
                        </BarrelAgedText>
                    </Barrel>
                }
                <BeersNotRecommended selectedBeers={notRecommendedBeers}/>
                <Footer>
                    <SocialButtons>
                        <SocialButton href='https://facebook.com/Piwolucja' target='_blank' rel='noreferrer noopener'>
                            <SocialButtonLogo src={facebookLogo} alt='facebook logo'/>
                        </SocialButton>
                        <SocialButton href='https://instagram.com/lukasz_matusik' target='_blank' rel='noreferrer noopener'>
                            <SocialButtonLogo src={instagramLogo} alt='instagram logo'/>
                        </SocialButton>
                        <SocialButton href='https://untappd.com/user/lukasz_matusik' target='_blank' rel='noreferrer noopener'>
                            <UntappdLogo src={untappdLogo} alt='untappd logo'/>
                        </SocialButton>
                    </SocialButtons>
                    COPYRIGHTS 2019-{currentYear}
                    <PiwolucjaLink href="https://piwolucja.pl/" target='_blank' rel='noreferrer noopener'>&nbsp;PIWOLUCJA</PiwolucjaLink>
                    <br/>DANE DZIĘKI UPRZEJMOŚCI
                    <PiwolucjaLink href="https://www.polskikraft.pl/" target='_blank' rel='noreferrer noopener'>&nbsp;POLSKIKRAFT.PL&nbsp;</PiwolucjaLink>
                    ORAZ
                    <PiwolucjaLink href="https://ontap.pl/" target='_blank' rel='noreferrer noopener'>&nbsp;ONTAP.PL&nbsp;</PiwolucjaLink>
                </Footer>
            </ResponseBeers>
        );
    }
}

const TopBar = styled.a`
    width: 100%;
    height: 48px;
    font-family: BiGPoiNts;
    font-size: 36px;
    text-align: center;
    color: #fcfcf5;
    background-color: #fbc602;
    position: fixed;
    top: 0px;
    z-index: 2;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    text-decoration: none;
    cursor: pointer;
`;

const TopLogo = styled.h1`
    font-family: BiGPoiNts;
    font-size: 36px;
    margin: 0;
    font-weight: normal;
`;

const BarrelAgedText = styled.p`
    text-align: center;
    width: 100%%;
    color: #5c4d38;
    padding: 0 30px;
`;

const ResponseBeers = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`;

const Footer = styled.footer`
    font-size: 11px;
    text-align: center;
    padding-bottom: 22px;
    color: #5c4d38;
    line-height: 2;
`;

const PiwolucjaLink = styled.a`
    text-decoration: none;
    color: #5c4d38;
`;

const SocialButtons = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 20px 15px 20px;
`;

const SocialButton = styled.a`
    display: flex;
    flex-grow: 1;
    justify-content: center;
`;

const SocialButtonLogo = styled.img`
    height: 35px;
    width: 35px;
`;

const UntappdLogo = styled.img`
    height: 35px;
    width: 35px;
    border-radius: 3px;
`;

const RecommendedBarrel = styled.img`
    height: 50px;
    top: 10px;
    position: relative;
`;

const Barrel = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 30px;
    max-width: 927px;
`;
