import React, { Component } from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'

import beer1 from '../beerIcons/beer1.svg';
import beer2 from '../beerIcons/beer2.svg';
import beer3 from '../beerIcons/beer3.svg';
import beer4 from '../beerIcons/beer4.svg';
import beer5 from '../beerIcons/beer5.svg';
import spinner from '../beerIcons/spinner.svg'

/* eslint-disable */
const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
export class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            correct: false,
            sendNewsletter: false,
            visible: false,
            isSending: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { userName } = this.props;
        this.setState({
            visible: true,
        })

        if (userName) {
            this.loader()
        }

    }

    validate = (email) => {
        const formErrors = {};

        if (email.length === 0) {
            formErrors.email = 'W celu otrzmania newslettera wymagane jest podanie adresu email.'
        } else if (!(emailRegex.test(email))) {
            formErrors.email = 'Niepoprawny adres email.'
        }

        return formErrors
    }

    radioInputChecked = eventName => {
        const { sendNewsletter } = this.state;
        this.setState({sendNewsletter: !sendNewsletter})
    };
    
    handleChangeEmail = e => {
        const reset = {email: false};
        e.target.value !== '' && this.setState({ formErrors: reset });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ correct: false })
        const {answers, sendAnswers} = this.props;
        const { sendNewsletter } = this.state;

        const expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 1);

        if(sendNewsletter) {
            const email = ReactDOM.findDOMNode(this._email).value;
            const formErrors = this.validate(email);
            this.setState({ formErrors: formErrors })

            if (!Object.keys(formErrors).length) {
                this.setState({ correct: true, isSending: true })
                sendAnswers(answers, email, sendNewsletter);
            }
        } else {
            this.setState({ correct: true, isSending: true })
            sendAnswers(answers, '', sendNewsletter);
        }
    };

    loader() {
        const { sendAnswers, answers } = this.props;
        const icons = [
            beer1,
            beer2,
            beer3,
            beer4,
            beer5,
        ];

        function displayIcons() {
            var i;
            for (i = 0; i < icons.length; i++) {
                show(i);
            }
        }

        function show(i) {
            setInterval(function() {
                document.getElementById('loader').style.backgroundImage = `url(${icons[i]})`;
            }, 1000 + i * 1000);
        }

        displayIcons();
        sendAnswers(answers, '', false)
    }

    render() {
        const { userName, questionLength } = this.props;
        const { formErrors, isSending, sendNewsletter } = this.state;

        return (
            <React.Fragment>
                <TopBar href='http://degustator.piwolucja.pl/'>
                    <TopLogo>Degustator</TopLogo>
                </TopBar>
                {userName
                    ?   <Loader>
                            <Beer id='loader'/>
                        </Loader>
                    : <Form className="section" key={questionLength+1} id={questionLength+1} onSubmit={this.handleSubmit} novalidate>
                        <CenterSection>
                            <QuestionNumber>Jeśli chcesz możesz dołączyć do
                                <a href='https://piwolucja.pl/newsletter'
                                    style={{color: '#dc8400', textDecoration: 'none'}}
                                    target='_blank'
                                    rel='noreferrer noopener'>&nbsp;newslettera...
                                </a>
                            </QuestionNumber>

                            <RadioContainer>
                                <Label htmlFor="sendNewsletter">Chcę otrzymać darmowego e-booka<br/>i dołączyć do newslettera
                                    <InputRadio
                                        type="checkbox"
                                        id="sendNewsletter"
                                        name={'sendNewsletter'}
                                        value={'sendNewsletter'}
                                        onClick={() => this.radioInputChecked('sendNewsletter')}
                                    />
                                    <Checkmark></Checkmark>
                                </Label>
                            </RadioContainer>

                            {sendNewsletter &&
                                <Container>
                                    <Email
                                        ref={email => (this._email = email)}
                                        className={this.state.formErrors.email && this.state.formErrors.email.length > 0 ? 'error' : null}
                                        type='email'
                                        name='email'
                                        placeholder='Email'
                                        onChange={this.handleChangeEmail}
                                    >
                                    </Email>

                                    {formErrors.email && formErrors.email.length > 0 && (
                                        <Span>{formErrors.email}</Span>
                                    )}
                                </Container>
                            }

                            {isSending 
                                ? <SpinnerWraper>
                                    <Spinner src={spinner} alt="spinner"/>
                                </SpinnerWraper>
                                : <ButtonContainer>
                                    <ButtonBox>
                                        <SendButton type='subbmit' id="getRecommendation">Poleć mi piwa</SendButton>
                                    </ButtonBox>
                                </ButtonContainer>
                            }
                        </CenterSection>
                    </Form>}
            </React.Fragment>
        )
    }
}

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TopBar = styled.a`
    width: 100%;
    height: 48px;
    font-family: BiGPoiNts;
    font-size: 36px;
    text-align: center;
    color: #fcfcf5;
    background-color: #fbc602;
    position: fixed;
    top: 0px;
    z-index: 1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    text-decoration: none;
    cursor: pointer;
`;

const TopLogo = styled.h1`
    font-family: BiGPoiNts;
    font-size: 36px;
    margin: 0;
    font-weight: normal;
`;

const Loader = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Beer = styled.div`
    height: 200px;
    width: 200px;
    background-image: url(${beer5});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
`;

const QuestionNumber = styled.div`
    width: 247px;
    height: 19px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #dc8400;
    text-transform: uppercase;
    padding-top: 180px;

    @media (max-width: 600px) {
        padding-top: 111px;
    }

    @media (max-width: 320px) {
        padding-top: 80px;
    }
`;

const CenterSection = styled.div`
`;

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 15.4375rem;
    padding: 15px 0 30px 0;
`;

const Checkmark = styled.span`
    position: absolute;
    top: 5px;
    left: 0;
    width: 16px;
    height: 16px;
    border: solid 1px #fbc602;
    border-radius: 1px;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }

    &:after {
        left: 4px;
        width: 5px;
        height: 10px;
        border: solid 1px #fbc602;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

const InputRadio = styled.input`
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ ${Checkmark} {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: solid 1px #fbc602;
    }

    &:checked ~ ${Checkmark}:after {
        display: block;
    }
`;

const Label = styled.label`
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    white-space: pre;
    flex-wrap: wrap;
    font-size: 12px;
    padding-bottom: 22px;
    color: #dc8400;


    &: ${InputRadio} {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
`;

const SpinnerWraper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15.4375rem;

    border: none;
    margin-top: 18px;

    height: 48px;
    border-radius: 2px;
    background-color: #fbc602;
`;

const Spinner = styled.img`
    width: 40px;
    height: 40px;

    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const RadioContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 249px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 48px;
`;

const SendButton = styled.button`
  border: none;
  margin-top: 18px;
  cursor: pointer;
  padding: 15px;
  
  width: 268px;
  height: 48px;
  border-radius: 2px;
  background-color: #dc8400;
  
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: center;
  color: #fcfcf5;
  text-transform: uppercase;
  
  &:hover {
    background-color: #fbc602;
  }

  &:focus {
    outline: none;
  }
`;

const Name = styled.input`
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dc8400;
  outline: none;
  width: 15.4375rem;
  color: #5c4d38;
  
  &:focus {
    border-bottom: 1px solid #fbc602;
  }

  &.error {
    border: 1px solid #ff7c2e;
    border-radius: 2px;
  }

  ::placeholder {
    color: #dc8400;
  }
`;

const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  font-size: 0.7rem;
  color: #ff7c2e;
  padding-top: 5px;
`;

const Email = styled.input`
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dc8400;
  outline: none;
  width: 15.4375rem;
  color: #5c4d38;
  
  &:focus {
    border-bottom: 1px solid #fbc602;
  }

  &.error {
    border-bottom: 2px solid #ff7c2e;
    border-radius: 2px;
  }

  ::placeholder {
    color: #dc8400
  }
`;

const ButtonContainer =styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 15.4375rem;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 15.4375rem;
`;
