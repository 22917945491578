import React, { Component } from 'react'
import styled from 'styled-components'
import circle from '../../beerIcons/circleShape.png';

export class BeersNotRecommended extends Component {

    copyToClipboard() {
        const dummy = document.createElement("textarea");
        const tooltip = document.getElementById("myTooltip");
        const text = window.location.href;
        if (dummy) {
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            tooltip.innerHTML = "Link został skopiowany";
        }
    }

    render() {
        const {selectedBeers} = this.props;

        return (
            <SelectedBeers>
                <SelectedBeerTitle>Piw w tych stylach unikaj</SelectedBeerTitle>
                    <BeerContainer>
                        <BeerExamples>
                            {selectedBeers.map((beer, index) => {
                                return (
                                    <BeerBox key={`BeerBox-${index}`}>
                                        <BeerStyleBox>
                                            <BeerStyleText>
                                                {beer.name}
                                            </BeerStyleText>
                                            {beer.otherName && <BeerStyleText>
                                                {`(${beer.otherName})`}
                                            </BeerStyleText>}
                                        </BeerStyleBox>
                                        {beer.polishName && <BeerPolishName>
                                            {beer.polishName}
                                        </BeerPolishName>}
                                    </BeerBox>
                                )
                            })}
                        </BeerExamples>
                    </BeerContainer>
                    <ButtonContainer>
                        <Restart href='https://degustator.piwolucja.pl'>
                            <StartOverButton>Zacznij od nowa</StartOverButton>
                        </Restart>
                        <Restart>
                            <ShareButton onClick={this.copyToClipboard} id="myTooltip">
                                Podziel się wynikami
                            </ShareButton>
                        </Restart>
                    </ButtonContainer>
            </SelectedBeers>
        )
    }
}

const SelectedBeers = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 600px) {
        padding-bottom: 25px;
    }
`;

const SelectedBeerTitle = styled.h2`
    width: 100%;
    font-family: Lato;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #dc8400;
    text-transform: uppercase;
    margin: 0;
    padding: 74px 10px 0px 10px;

    @media (max-width: 600px) {
        font-size: 24px;
        padding: 50px 10px 0px 10px;
    }
`;

const BeerBox = styled.div`
    width: 157px;
    height: 157px;
    border-radius: 2px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-wrap: wrap;

    background-image: url("${circle}");
    background-color: #fcfcf5;
    background-repeat: no-repeat;
    background-position: right 8px top 8px;

    @media (max-width: 359px) {
        width: 140px;
        height: 140px;
        margin-bottom: 20px;
    }

    @media (min-width: 360px) {
        margin: 0 10px 20px 10px;
    }
`;

const BeerStyleBox = styled.div`
    padding: 16px;
    align-self: flex-start;
`;

const BeerStyleText = styled.div`
    font-family: Lato;
    font-size: 18px;
    color: #5c4d38;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media (mmin-width: 360px) {
        width: 132px;
    }

    @media (max-width: 360px) {
        font-size: 16px;
    }
`;

const BeerPolishName = styled.div`
    width: 100%;
    font-family: Lato;
    font-size: 14px;
    color: #dc8400;
    padding: 0 16px 16px 16px;
    align-self: flex-end;

    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
`;

const BeerExamples = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 120px 0 120px 0;
    width: 100%;

    @media (max-width: 656px) {
        padding: 64px 0 0 0;
    }
`;

const BeerContainer = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #fcfcf5 160px, #fbc602 0px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 600px) {
        background: linear-gradient(180deg,#fcfcf5 20%,#fbc602 20% 88%,#fcfcf5 88%);
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fbc602;
    flex-wrap: wrap;

    @media (max-width: 615px) {
        background: none;
        flex-direction: column-reverse;
        -moz-flex-direction: column-reverse;
        align-items: center;
    }
`;

const StartOverButton = styled.button`
    width: 268px;
    height: 48px;
    border-radius: 2px;
    background-color: #dc8400;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    margin-bottom: 69px;
    cursor: pointer;
    color: #fcfcf5;

    &:focus {
        outline: none;
    }
`;

const ShareButton = styled.button`
    width: 268px;
    height: 48px;
    border-radius: 2px;
    background-color: #A01862;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    margin-bottom: 69px;
    cursor: pointer;
    color: #fcfcf5;

    @media (max-width: 615px) {
        margin-top: 10vh;
        margin-bottom: 35px;
    }

    &:focus {
        outline: none;
    }
`;

const Restart = styled.a`
    margin: 0 20px;
    text-decoration: none;
`;
