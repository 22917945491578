import React, { Component } from 'react'
import styled from 'styled-components'

import { QuestionBox } from './components/QuestionBox.js'
import { UserAnswersConsumer } from "../UserAnswers.context";

const renderQuestions = (elem, index, unmountMe, questionLength) => (
    <QuestionHeader className="section" key={index} id={index} >
        <QuestionNumber>{index + 1}/{questionLength}</QuestionNumber>
        <Header>{elem.question}</Header>
        <Info>{elem.tooltip}</Info>
      {elem.answers ?
          <QuestionBox
            parentIndex={index}
            question={elem.question}
            answer={elem.answers}
            unmountMe={unmountMe}
          />
        : <QuestionBox
            parentIndex={index}
            question={elem.question}
            answer={['tak', 'nie', 'nie wiem']}
            unmountMe={unmountMe}
          />}
    </QuestionHeader>
  );

export class Question extends Component {
    render() {
        const { unmountMe, questionLength } = this.props;
        return (
          <QuestionFragment>
            <TopBar href='http://degustator.piwolucja.pl/'>
              <TopLogo>Degustator</TopLogo>
            </TopBar>
            <UserAnswersConsumer>
                {({ questions }) => (
                    <div>
                        {questions
                            && <React.Fragment>
                                {questions.map((elem, index) => renderQuestions(elem, index, unmountMe, questionLength))}
                            </React.Fragment>}
                    </div>
                )}
            </UserAnswersConsumer>
          </QuestionFragment>
        )
    }
}

const QuestionFragment = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Header = styled.div`
  align-items: center;
  justify-content: center;
  color: #5c4d38;
  margin: 0 2rem;
  text-align: center;
  font-size: 21px;
  padding-top: 26px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const TopBar = styled.a`
  width: 100%;
  height: 48px;
  text-align: center;
  color: #fcfcf5;
  background-color: #fbc602;
  position: fixed;
  top: 0px;
  z-index: 1;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  text-decoration: none;
  cursor: pointer;
`;

const TopLogo = styled.h1`
  font-family: BiGPoiNts;
  font-size: 36px;
  margin: 0;
  font-weight: normal;
`;

const QuestionNumber = styled.div`
    width: 34px;
    height: 19px;
    font-size: 16px;
    text-align: center;
    color: #dc8400;
    padding-top: 176px;

    @media (max-width: 600px) {
      padding-top: 110px;
    }

    @media (max-width: 320px) {
      padding-top: 75px;
    }
`;

const QuestionHeader = styled.div`
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  display: flex;
  &:not(:first-child) {
      opacity: 0;
      display: none;
      transform: translateY(100px);
      all: 0px;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
  }
`;

const Info = styled.div`
  font-size: 14px;
  padding-top: 9px;
  color: #5c4d389c;
  max-width: 500px;
  text-align: center;
  margin: 0 2rem;
`;
